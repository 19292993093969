.ant-picker-range {
  position: relative;
  display: inline-flex;
  height: 50px;
}
.column-sms_content span {
  word-wrap: break-word;
  white-space: pre;
  max-width: 150px;
  max-height: 27px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ant-popover-inner {
  margin: auto;
  max-width: 80%;
}

/* invoice POS */
.table-items {
  border-collapse: collapse;
  width: 100%;
}

.table-items__th {
  border: 1px solid #b1b9c0;
  text-align: left;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #212b36;
  padding: 2px 6px;
  font-weight: 600;
}

.table-items__td {
  border: 1px solid #b1b9c0;
  text-align: left;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #212b36;
  padding: 2px 6px;
  font-weight: 400;
  vertical-align: top;
}

/* .table-items .tr-item:nth-child(odd) {
  background-color: #F4F6F8;
} */

.cursor-pointer {
  cursor: pointer;
}

.ant-modal-mask, .ant-modal-wrap {
  z-index: 10000;
}

.input-antd .ant-select-selector {
  height: 40px !important;
  border-radius: 8px !important;
}

.input-antd input {
  height: 38px !important;
}

.input-antd .ant-select-selection-item::after {
  line-height: 38px !important;
}

.input-antd .ant-select-selection-placeholder {
  line-height: 38px !important;
}

/* range picker */
.input-antd .ant-picker {
  height: 56px !important;
  border-radius: 8px !important;
}

@page {
  margin: 24px;
  padding: 0px;
}